<template>
  <page-content>
    <page-title heading="การจัดการโรงเรียน">
      <template v-slot:right>
        <b-button variant="danger" @click="onCreate"
          >เพิ่มรายชื่อโรงเรียน</b-button
        >
      </template>
    </page-title>

    <b-row>
      <b-col cols="12">
        <filter-master
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          :hiddenInput="{ school: true, schoolClass: true }"
        >
        </filter-master>
      </b-col>

      <!-- <div class="col-12">
        <filter-province
          v-model="filterProvince"
          :m-province-id="filterProvince.mProvinceId"
          :m-amphur-id="filterProvince.mAmphurId"
        >
        </filter-province>
      </div> -->

      <b-col cols="10" md="6" class="mt-2 pr-0">
        <b-form-group class="px-2">
          <b-input-group class="input-group-seamless">
            <b-form-input
              placeholder="ค้นหารายชื่อโรงเรียน"
              v-model="keyword"
              @keyup.enter="onKeywordSearched"
            ></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="2" class="mt-2 pl-0">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched"
          >ค้นหา</b-button
        >
      </b-col>
    </b-row>

    <school-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="schoolTableParams"
      @edit="onEdit"
      @delete="onDelete"
    />

    <create-or-update-school
      :edit-data.sync="editData"
      v-model="showModalSchool"
      @create:success="onCreateSuccess"
    ></create-or-update-school>

    <delete-school
      v-model="showModalDelete"
      @delete:success="onDeleteSuccess"
      :delete-data.sync="deleteData"
    ></delete-school>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateSchool from "../../components/modal/CreateOrUpdateSchool";
import SchoolTable from "../../components/table/School";
import FilterMaster from "../../components/form/FilterMaster";
// import FilterProvince from "../../components/form/FilterProvince";
import DeleteSchool from "../../components/modal/DeleteSchool";
import { Auth } from "../../models";

export default {
  components: {
    PageContent,
    PageTitle,
    CreateOrUpdateSchool,
    SchoolTable,
    DeleteSchool,
    FilterMaster,
    // FilterProvince,
  },

  data() {
    return {
      keyword: "",
      formfilter: {},
      // filterProvince: {},
      editData: {},
      deleteData: {},
      showModalSchool: false,
      showModalDelete: false,
      fields: [
        { key: "school", label: "ชื่อโรงเรียน", sortable: true },
        { key: "province", label: "จังหวัด", sortable: true },
        { key: "amphur", label: "อำเภอ", sortable: true },
        {
          key: "created_at",
          label: "วันที่สร้าง",
        },
        {
          key: "updated_at",
          label: "แก้ไขล่าสุด",
        },
      ],
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    schoolTableParams() {
      const { formfilter } = this;

      return {
        ...formfilter,
        // ...filterProvince,
      };
    },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    // onFormFilterChanged(formFilter) {
    //   const query = {};
    //   const { authUser } = this;

    //   if (!authUser.mHeadquarterId && formFilter.mHeadquarterId) {
    //     this.$set(query, "mHeadquarterId", formFilter.mHeadquarterId);
    //   }

    //   if (!authUser.mDivisionId && formFilter.mDivisionId) {
    //     this.$set(query, "mDivisionId", formFilter.mDivisionId);
    //   }

    //   if (!authUser.mStationId && formFilter.mStationId) {
    //     this.$set(query, "mStationId", formFilter.mStationId);
    //   }

    //   console.log(query, formFilter);
    //   if (Object.keys(query).length) {
    //     this.$router.replace({
    //       name: "MasterDataSchool",
    //       query,
    //     });
    //   }
    // },

    onCreate() {
      this.showModalSchool = true;
    },

    async onEdit(item) {
      this.editData = { ...item };

      //   const promise = await User.api().findAll({
      //     teachingDareId: item.id,
      //   });

      //   const { data = [] } = promise.response.data;

      //   if (data.length) {
      //     this.$set(
      //       this.editData,
      //       "teaching_plans",
      //       sortBy(data, "createdAt").map(
      //         ({ lesson_number, lesson_name, date, time, content }) => ({
      //           lesson_number,
      //           lesson_name,
      //           date,
      //           time,
      //           content,
      //         })
      //       )
      //     );
      //   }

      this.showModalSchool = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
};
</script>
